/* asap-regular - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  src: local('Asap Regular'), local('Asap-Regular'),
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$igStatic}skins/fonts/Asap/sap-v11-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-500 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  src: local('Asap Medium'), local('Asap-Medium'),
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 400;
  src: local('Asap Italic'), local('Asap-Italic'),
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-500italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 500;
  src: local('Asap Medium Italic'), local('Asap-MediumItalic'),
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-600 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  src: local('Asap SemiBold'), local('Asap-SemiBold'),
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-700 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  src: local('Asap Bold'), local('Asap-Bold'),
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-600italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 600;
  src: local('Asap SemiBold Italic'), local('Asap-SemiBoldItalic'),
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-700italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 700;
  src: local('Asap Bold Italic'), local('Asap-BoldItalic'),
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$igStatic}skins/fonts/Asap/asap-v11-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
