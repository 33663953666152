@font-face {
  font-family: 'lato';
  src: url('#{$igStatic}skins/fonts/lato/lato-blackitalic-webfont.woff2') format('woff2'), url('#{$igStatic}skins/fonts/lato/lato-blackitalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'lato';
  src: url('#{$igStatic}skins/fonts/lato/lato-hairline-webfont.woff2') format('woff2'), url('#{$igStatic}skins/fonts/lato/lato-hairline-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'lato';
  src: url('#{$igStatic}skins/fonts/lato/lato-light-webfont.woff2') format('woff2'), url('#{$igStatic}skins/fonts/lato/lato-light-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'lato';
  src: url('#{$igStatic}skins/fonts/lato/lato-black-webfont.woff2') format('woff2'), url('#{$igStatic}skins/fonts/lato/lato-black-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'lato';
  src: url('#{$igStatic}skins/fonts/lato/lato-bolditalic-webfont.woff2') format('woff2'), url('#{$igStatic}skins/fonts/lato/lato-bolditalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'lato';
  src: url('#{$igStatic}skins/fonts/lato/lato-bold-webfont.woff2') format('woff2'), url('#{$igStatic}skins/fonts/lato/lato-bold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'lato';
  src: url('#{$igStatic}skins/fonts/lato/lato-lightitalic-webfont.woff2') format('woff2'), url('#{$igStatic}skins/fonts/lato/lato-lightitalic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
