// ****** Read here how to update the gridscale iconfont:  https://ingrid-storybook.eu-central-1.gos3.io/?path=/story/general-developers-updating-3rd-party-libs--page *****

@font-face {
  font-family: "gridscale_iconfont";
  src: url("#{$igStatic}gsiconfont/v24/gridscale_iconfont.eot");
  src: url("#{$igStatic}gsiconfont/v24/gridscale_iconfont.eot#iefix")
      format("embedded-opentype"),
    url("#{$igStatic}gsiconfont/v24/gridscale_iconfont.woff2") format("woff2"),
    url("#{$igStatic}gsiconfont/v24/gridscale_iconfont.woff") format("woff"),
    url("#{$igStatic}gsiconfont/v24/gridscale_iconfont.ttf") format("truetype"),
    url("#{$igStatic}gsiconfont/v24/gridscale_iconfont.svg#iconfont")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@import "gsiconfont.mixins";

[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="gicon-"]:before,
[class*=" gicon-"]:before {
  @include gicon();
}
