body {
  .p-inputtext {
    font-size: 14px;
    line-height: 1.25rem;

    &.p-textinput__fullwidth {
      width: 100%;
    }

    &.p-textinput__small {
      font-size: 14px;
      padding: 0.857em 1em;
    }
    &.p-textinput__padding_right {
      padding-right: 60px;
    }
    &.p-textinput__padding_left {
      padding-left: 40px;
    }

    &.ng-invalid {
      &.ng-dirty {
        @include invalid-input();
      }
      &.ng-touched:not(:focus) {
         @include invalid-input();
      }
    }
  }
}
