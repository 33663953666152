@import 'public/foundations_vars';


.a11y-visible-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  z-index: -1;

  &:focus {
    display: block;
    padding: initial;
    clip: auto;
    height: auto;
    overflow: auto;
    position: fixed;
    z-index: 9999;
    top: 80px;
    left: 0;
    width: 100vw;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 2px solid var(--primary-color);
    background: var(--ig-color-white80);
  }
}


.ig-a11ylink {
  cursor: pointer;
}
