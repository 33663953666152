@font-face {
  font-family: 'Me Web';
  src: url('#{$igStatic}skins/fonts/MeWeb/FSMeWebHeavyItalic/fs_me_web-heavy-italic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Me Web';
  src: url('#{$igStatic}skins/fonts/MeWeb/FSMeWebHeavy/fs_me_web-heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Me Web';
  src: url('#{$igStatic}skins/fonts/MeWeb/FSMeWebBoldItalic/fs_me_web-bold-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Me Web';
  src: url('#{$igStatic}skins/fonts/MeWeb/FSMeWebBold/fs_me_web-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Me Web';
  src: url('#{$igStatic}skins/fonts/MeWeb/FSMeWebItalic/fs_me_web-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Me Web';
  src: url('#{$igStatic}skins/fonts/MeWeb/FSMeWebRegular/fs_me_web-regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Me Web';
  src: url('#{$igStatic}skins/fonts/MeWeb/FSMeWebLightItalic/fs_me_web-light-italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Me Web';
  src: url('#{$igStatic}skins/fonts/MeWeb/FSMeWebLight/fs_me_web-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

