
 //Mixin
 @mixin igTypoHeadline1 {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoPanelFontWeightBold;
  font-size: $igTypoH1Size;
  line-height: $igTypoH1LineHeight;
  color: var(--h1-color);
}
@mixin igTypoHeadline2 {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoPanelFontWeightBold;
  font-size: $igTypoH2Size;
  line-height: $igTypoH2LineHeight;
  color: var(--h2-color);
}
@mixin igTypoHeadline3 {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoPanelFontWeightBold;
  font-size: $igTypoH3Size;
  line-height: $igTypoH3LineHeight;
  color: var(--h3-color);
}
@mixin igTypoHeadline4($colored:true) {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoPanelFontWeightBold;
  font-size: $igTypoH4Size;
  line-height: $igTypoH4LineHeight;
  color: var(--ig-color-label);

  @if $colored {
    color: var(--h4-color);
  }
}
@mixin igTypoHeadline5($colored:true) {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoPanelFontWeightBold;
  font-size: $igTypoH5Size;
  line-height: $igTypoH5LineHeight;
  color: var(--ig-color-label);

  @if $colored {
    color: var(--h5-color);
  }
}
@mixin igTypoHeadline6($colored:true) {
  font-family: $igTypoFontFamilyHeadline;
  font-weight: $igTypoPanelFontWeightBold;
  font-size: $igTypoH6Size;
  line-height: $igTypoH6LineHeight;
  color: var(--ig-color-label);

  @if $colored {
    color: var(--h6-color);
  }
}
@mixin igTypoPara {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoFontWeightDefault;
  font-size: $igTypoParaSize;
  line-height: $igTypoParaLineHeight;
}
@mixin igTypoStrong {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoFontWeightBold;
  font-size: $igTypoParaSize;
  line-height: $igTypoParaLineHeight;
}

@mixin igTypoSmall {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoFontWeightDefault;
  font-size: $igTypoSmallSize;
  line-height: $igTypoSmallLineHeight;
  //display: inline-block;
}

@mixin igTypoLabel {
  font-family: $igTypoFontFamily;
  font-weight: $igTypoLabelWeight;
  font-size: $igTypoLabelSize;
  line-height: $igTypoLabelHeight;
}

@mixin igTypoLabelAsTitle {
  display: block;
  @include igTypoHeadline6(false);
  color: var(--ig-color-label);

  sup {
    vertical-align: text-top;
  }
}







 /**
 Paragraph Sizes are default , medium and small
 **/
 /*
@mixin igTypoPanelPara($size : 'default') {
  font-family: $igTypoPanelFontFamily;
  font-weight: $igTypoPanelFontWeightDefault;

  // Pic correct Size
  @if $size == 'medium' {
    font-size: $igTypoPanelParaMediumSize;
    line-height: $igTypoPanelParaMediumLineHeight;
  } @else if $size == 'small' {
    font-size: $igTypoPanelParaSmallSize;
    line-height: $igTypoPanelParaSmallLineHeight;
  } @else {
    font-size: $igTypoPanelParaSize;
    line-height: $igTypoPanelParaLineHeight;
  }

  padding-top: $igTypoPanelPaddingTop;
  padding-bottom: $igTypoPanelPaddingBottom;
}
*/
/*
@mixin igTypoPanelStrong($size : 'default') {

  font-family: $igTypoPanelFontFamily;
  font-weight: $igTypoPanelStrongFontWeight !important;


  // Pic correct Size
  @if $size == 'medium' {
    font-size: $igTypoPanelParaMediumSize;
    line-height: $igTypoPanelParaMediumLineHeight;
  } @else if $size == 'small' {
    font-size: $igTypoPanelParaSmallSize;
    line-height: $igTypoPanelParaSmallLineHeight;
  } @else {
    font-size: $igTypoPanelParaSize;
    line-height: $igTypoPanelParaLineHeight;
  }
}
*/
/*
@mixin igTypoPanelHyperlink($size : 'default') {

  font-family: $igTypoPanelFontFamily;
  font-weight: $igTypoPanelFontWeightDefault;


  // Pic correct Size
  @if $size == 'medium' {
    font-size: $igTypoPanelParaMediumSize;
    line-height: $igTypoPanelParaMediumLineHeight;
  } @else if $size == 'small' {
    font-size: $igTypoPanelParaSmallSize;
    line-height: $igTypoPanelParaSmallLineHeight;
  } @else {
    font-size: $igTypoPanelParaSize;
    line-height: $igTypoPanelParaLineHeight;
  }

  text-decoration:          $igTypoPanelHyperlinkDecoration;
  text-underline-position:  $igTypoPanelHyperlinkDecorationPosition;
}
*/
/*
@mixin igTypoPanelSeperator() {

  font-family: $igTypoPanelFontFamily;
  font-weight: $igTypoPanelFontWeightDefault;


  font-size: $igTypoPanelSeparatorSize;
  line-height: $igTypoPanelSeparatorHeight;
  letter-spacing: $igTypoPanelSeparatorLetterSpacing;
  text-transform: $igTypoPanelSeparatorTransform;

  display: inline-block;;
  padding-top: $igTypoPanelSeparatorPaddingTop;
  padding-bottom: $igTypoPanelSeparatorPaddingBottom;


}
*/
/*
@mixin igTypoPanelLabel() {

  font-family: $igTypoPanelFontFamily;
  font-weight: $igTypoPanelLabelWeight;


  font-size: $igTypoPanelLabelSize;
  line-height: $igTypoPanelLabelHeight;
  text-transform: $igTypoPanelLabelTransform;

}
*/
