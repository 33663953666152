@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-SemiboldItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-Semibold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('#{$igStatic}skins/fonts/OpenSans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
