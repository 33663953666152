/**
* Variantes
*/

@import 'inputtext-icon.public.vars';


/**
  Icon Default
  */
.ig-inputtexticon {
  position: relative;

  > .ig-inputtexticon__icon {
    position: absolute;
    left: $igInputTextIconLeft;
    top: 50%;
    transform: translateY(-50%);
  }

  > .ig-inputtext {
    padding-left: $igInputTextIconPaddingLeft;
  }

  /**
  Icon right
   */
  &.ig-inputtexticon--right {
    > .ig-inputtexticon__icon {
      left: auto;
      right: $igInputTextRightIconRight;
    }

    > .ig-inputtext {
      padding-left: $igInputTextRightIconPaddingLeft;
      padding-right: $igInputTextRightIconPaddingRight;
    }
  }

  /**
  icon with background
  */
  &.ig-inputtexticon--icon-background {
    // Position icons
    > .ig-inputtexticon__icon {
      background: var(--ig-color-form-clickarea-background);
      line-height: $igInputTextIconBackgroundLineHeight;
      width: $igInputTextIconBackgroundWidth;
      top: 1px;
      transform: none;
      left: 1px;
      bottom: 1px;
      text-align: center;
      border-right: $igInputTextBackgroundBorderWidth solid var(--ig-color-card-border);
    }

    &.ig-inputtexticon--right {
      > .ig-inputtexticon__icon {
        left: auto;
        right: 1px;
        border-right: 0;
        border-left: $igInputTextBackgroundBorderWidth solid var(--ig-color-card-border);
      }
    }

  //Hover
    &:hover {
      > .ig-inputtexticon__icon {
        border-color: var(--ig-color-medium-blue);
      }
    }

    .ig-inputtext:focus + .ig-inputtexticon__icon {
      background: var(--ig-color-sky-blue20);
      border-color: var(--ig-color-medium-blue);
    }
  }
}
