@import 'foundations_vars';

@keyframes gs-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  .p-button {
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    line-height: 1.25rem;

    .p-button-label {
      text-transform: none;
      letter-spacing: 0;
    }

    &.p-button-loading {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.8;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .p-button-label {
        padding-left: 2em;
      }
      .p-button-icon-left {
        display: none;
      }

      &:before {
        content: ' ';
        display: inline-block;
        width: 0.75em;
        height: 0.75em;
        margin-left: 1px;
        margin-right: 8px;
        border-radius: 50%;
        border: 2px solid var(--primary-dark-color);
        border-color: var(--primary-dark-color) transparent var(--ig-color-white) transparent;
        animation: gs-loader 1.8s linear infinite;
        margin-left: 1em;
        position: absolute;
        top: 50%;
        margin-top: -0.5em;
        left: -5px;
      }

      &.p-button-secondary:before {
        border: 2px solid var(--primary-dark-color);
        border-color: var(--primary-color) transparent var(--primary-dark-color) transparent;
      }
    }

    &.p-disabled,
    &:disabled {
      border: none;
      background: var(--button-color-disable-overlay);
      box-shadow: none;
      cursor: auto;
      opacity: 1.0!important; // override the default styles in ingrid.public for .disabled class

      &.p-button-secondary.p-button-outlined {
        opacity: 0.4!important;
        border: 1px solid;
        .p-button-label, .p-button-icon {
          color: var(--button-color); // we don't use the disabled color for secondary button
        }
      }

      .p-button-label, .p-button-icon {
        color: var(--button-color-disabled);
      }
    }

    &.p-button-tiny {
      font-size: 12px;
      padding: 4px 10px;

      .p-button-icon {
        font-size: 12px;
      }
    }
  }
}
