// Brand Typographie (Panel)
// Design Tokens
$igTypoPanelFontFamily: var(--skin-font-family, Roboto);
$igTypoPanelFontWeightDefault: 400;
$igTypoPanelFontWeightRegular: 400;
$igTypoPanelFontWeightBold: 500;
$igTypoPanelFontWeightMedium: 500;
$igTypoPanelFontWeightLight: 300;
$igTypoPanelFontWeightItalic: italic;

$igTypoFontFamily: var(--skin-font-family, Roboto);
$igTypoFontFamilyHeadline: var(--skin-font-family-headline);
$igTypoFontWeightDefault: 400;
$igTypoFontWeightRegular: 400;
$igTypoFontWeightBold: 500;
$igTypoFontWeightMedium: 500;
$igTypoFontWeightLight: 300;
$igTypoFontWeightItalic: italic;


// =======================


//h1
$igTypoH1Size: 42px;
$igTypoH1LineHeight: normal;

//h2
$igTypoH2Size: 36px;
$igTypoH2LineHeight: normal;

//h3
$igTypoH3Size: 26px;
$igTypoH3LineHeight: normal;

//h4
$igTypoH4Size: 20px;
$igTypoH4LineHeight: normal;

//h5
$igTypoH5Size: 18px;
$igTypoH5LineHeight: normal;

//h6
$igTypoH6Size: 16px;
$igTypoH6LineHeight: normal;

//paragraph
$igTypoParaSize: 14px;
$igTypoParaLineHeight: 22px;

//small
$igTypoSmallSize: 12px;
$igTypoSmallLineHeight: normal;

//label
$igTypoLabelSize: $igTypoParaSize;
$igTypoLabelHeight: normal;
$igTypoLabelWeight: $igTypoFontWeightDefault;
$igTypoLabelWeightSelected: $igTypoFontWeightBold;


// =====================








$igTypoPanelFontDecorationUnderline: underline;

$igTypoPanelFontUnderlinePosition: under;
$igTypoPanelFontTransformUpper: upper;

// Default Spacings Panel Typo
$igTypoPanelPaddingTop : 0px;
$igTypoPanelPaddingBottom: 0px;

//PARAGRAPH
$igTypoPanelParaSize: 16px;
$igTypoPanelParaLineHeight: 24px;

$igTypoPanelParaMediumSize: 14px;
$igTypoPanelParaMediumLineHeight: 22px;

$igTypoPanelParaSmallSize: 12px;
$igTypoPanelParaSmallLineHeight: 18px;

//Strong Style
$igTypoPanelStrongFontWeight: $igTypoPanelFontWeightBold;

//Link Styles
$igTypoPanelHyperlinkDecoration: $igTypoPanelFontDecorationUnderline;
$igTypoPanelHyperlinkDecorationPosition: $igTypoPanelFontUnderlinePosition;


// Separator Styles
$igTypoPanelSeparatorSize: 12px;
$igTypoPanelSeparatorHeight: 12px;
$igTypoPanelSeparatorLetterSpacing: 1.5px;
$igTypoPanelSeparatorTransform: uppercase;
$igTypoPanelSeparatorPaddingTop : 15px;
$igTypoPanelSeparatorPaddingBottom: 15px;

// Label
$igTypoPanelLabelSize: 16px;
$igTypoPanelLabelHeight: 20px;
$igTypoPanelLabelWeight: $igTypoPanelFontWeightDefault;
$igTypoPanelLabelWeightSelected: $igTypoPanelFontWeightBold;
$igTypoPanelLabelTransform: none;
