/**
* Ingrid InputTexts
*/

/**
* Variantes
*/

//Primary (default)

@import 'inputtext.public.vars';


.ig-inputtext {
  font-family: $igInputTextFontFamily;
  font-weight: $igInputTextFontWeight;
  font-size: $igInputTextFontSize;
  height: $igInputTextHeight;
  border: $igInputTextBorderWidth solid var(--ig-color-card-border);
  border-radius: $igInputTextBorderRadius;
  padding: $igInputTextPadding;
  width: 100%;

  &:hover {
    background-color: transparent;
    border: $igInputTextBorderWidth solid $igInputTextBorderColor;
    border-color: $igInputTextHoverBorderColor;
  }
  &:focus {
    background-color: transparent;
    border: $igInputTextBorderWidth solid $igInputTextBorderColor;
    border-color: $igInputTextHoverBorderColor;
    box-shadow: $igInputTextActiveShadow;
  }

  /**
  Large
  **/

  &.ig-inputtext--large {
    font-size: $igInputTextLargeFontSize;
    height: $igInputTextLargeHeight;
    line-height: $igInputTextLargeLineHeight;
    padding: $igInputTextLargePadding;
    &:hover,
    &:focus {
      background-color: transparent;
      border: $igInputTextBorderWidth solid $igInputTextBorderColor;
      border-color: $igInputTextHoverBorderColor;
    }
    &:active {
      box-shadow: $igInputTextActiveShadow;
    }
  }

  /**
  Rounded
  **/
  &.ig-inputtext--rounded {
    border-radius: $igInputTextRoundedBorderRadious;
  }

  /**
  Disable
  */
  &[disabled] {
    box-shadow: none;
    border: $igInputTextBorderWidth solid var(--ig-color-card-border);
    opacity: 0.5;
  }

  /**
  Readonly
  */

  &[readonly] {
  }
}
// Helper
.ig-padding25 {
  padding: 25px;
}

/* Floating Label */
/* Floating Label */
.ig-float-label {
  display: block;
  position: relative;
  margin-top: 10px;

  & > .ig-label {
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--ig-color-label);
    display: inline-block;
    padding-bottom: 5px;
    transform: translateY(-50%);
    margin: 0;
    padding-bottom: 0;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 7px;
    top: 50%;
    margin-top: -0.5em;
    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
    line-height: 1;
    display: inline-block;
    padding: 0 7px;
    background: var(--ig-color-white);
  }

  .ig-inputtext,
  .ig-inputtext:focus {
    background: var(--ig-color-white);
  }

  input::placeholder {
    opacity: 0;
    transition: inherit;
  }
  input:focus::placeholder {
    opacity: 1;
  }

  input:focus + .ig-label,
  input:not(:placeholder-shown) + .ig-label,
  input:valid + .ig-label,
  input:-webkit-autofill + .ig-label {
    top: 0;
    font-size: 10px;
  }

  &.ig-has-placeholder,
  &.ig-focused,
  &.ig-has-value {
    & > label {
      top: -0.75em;
      font-size: 12px;
    }
  }
}
