/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-100.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-100italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-300italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-500italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-700italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'), url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-900italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$igStatic}skins/fonts/Roboto/roboto-v20-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
