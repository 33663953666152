// Panel Class to Root node activates the panel Styles
&.ig-panel,
.ig-panel {
  @include igTypoPara();

  a {
    color: var(--link-color);
  }


  h1,
  .ig-h1 {
    @include igTypoHeadline1();
  }

  h2,
  .ig-h2 {
    @include igTypoHeadline2();
  }

  h3,
  .ig-h3 {
    @include igTypoHeadline3();
  }

  h4,
  .ig-h4 {
    @include igTypoHeadline4();
  }
  .ig-h4-stealth {
    @include igTypoHeadline4(false);
  }

  h5,
  .ig-h5 {
    @include igTypoHeadline5();
  }
  .ig-h5-stealth {
    @include igTypoHeadline5(false);
  }

  h6,
  .ig-h6 {
    @include igTypoHeadline6();
  }
  .ig-h6-stealth {
    @include igTypoHeadline6(false);
  }

  p,
  .ig-text {
    @include igTypoPara();
  }

  strong,
  .ig-text-strong {
    @include igTypoStrong();
  }

  small,
  .ig-text-small {
    @include igTypoSmall();
  }

  label,
  .ig-label {
    @include igTypoLabel();

    &.ig-label--is-selected {
      font-weight: $igTypoLabelWeightSelected;
    }
  }
  .ig-label-title {
    @include igTypoLabelAsTitle();
  }




}

/**
   Custom Classes
   */
   /*
.ig-typo-panel-para {
  @include igTypoPanelPara();
}

.ig-typo-panel-para--medium {
  @include igTypoPanelPara('medium');
}

.ig-typo-panel-para--small {
  @include igTypoPanelPara('small');
}

// Hyperlinks
.ig-typo-panel-hyperlink {
  @include igTypoPanelHyperlink();
}

// Typographie Bold etc..
.ig-typo-panel-strong {
  @include igTypoPanelStrong();
}

.ig-typo-panel-strong--medium {
  @include igTypoPanelStrong('medium');
}

.ig-typo-panel-strong--small {
  @include igTypoPanelStrong('small');
}

.ig-typo-panel-seperator {
  @include igTypoPanelSeperator();
}

.ig-typo-panel-label {
  @include igTypoPanelLabel();
}
.ig-typo-panel-label--selected {
  font-weight: $igTypoPanelLabelWeightSelected;
}

// Padding Helper for Demo Case only
.ig-typo-panel--hightlight_padding {
  @include _igTypoHelperPadding($igTypoPanelPaddingTop, $igTypoPanelPaddingBottom);
}

.ig-typo-panel-seperator--hightlight_padding {
  @include _igTypoHelperPadding($igTypoPanelSeparatorPaddingTop, $igTypoPanelSeparatorPaddingBottom);
}
*/
