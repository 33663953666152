body {
  .p-card {
    &.p-card-hoverable {
      position: relative;
      transition: box-shadow 0.4s, transform 0.4s;

      .p-card-hoverable-action-link {
        position: absolute;
        top: 8px;
        right: 8px;
        color: var(--link-color);
        transition: color 0.4s, transform 0.4s;
        &:hover {
          transform: scale(1.25);
        }
      }

      .p-card-content {
        padding-top: 28px;
      }

      .p-card-title {
        padding-right: 28px;
        & + .p-card-content {
          top: initial;
        }
      }

      &.p-card-hovered {
        box-shadow: var(--box-shadow) !important;

        cursor: pointer;

        .p-card-hoverable-action-link {
          color: var(--primary-color);
        }
      }
    }
  }
}
