@import 'foundations_vars';
.gs-dialog {
  background: #f5f5f5;
  padding: 0;

  @include respond-to(medium up) {
    padding: 10px;
  }

  .ui-dialog-content {
    background: var(--ig-color-white);
    padding: 0;
    border-bottom: 10px solid #f5f5f5;
  }

  &.small {
    .ui-dialog-content {
      border-bottom: 0;
    }
  }

  .gs-dialog-wrapper {
    font-size: 20px;
    text-align: center;

    .gs-dialog-header {
      //margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: var(--ig-color-white);
      position: relative;
      padding: 15px 10px;
      border-bottom: 10px solid #f5f5f5;

      .fas.fa-circle {
        color: var(--primary-dark-color);
      }

      h2 {
        margin: 0;
        color: var(--primary-dark-color);
        text-align: left;
        padding-left: 10px;

        &.smaller {
          font-size: 22px;
          line-height: 30px;
        }
      }

      .required_hint {
        color: #999;
        font-size: 14px;
        top: 15px;
        right: 14px;
        position: absolute;
      }
    }

    .gs-dialog-box {
      background: var(--ig-color-white);
      padding: 10px;
      // border-bottom: 10px solid #f5f5f5;

      &.no-margin {
        margin: 0;
      }
    }

    .gs-dialog-content {
      text-align: left;
      font-size: 1rem;
    }
  }

  .gs-dialog-footer {
    text-align: right;
    background: var(--ig-color-white);
    padding: 10px;
  }
}
