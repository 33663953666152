.favorite-switch {
    cursor: pointer;
    color: var(--text-secondary-color);
    margin-left: 5px;
    font-size: 0.8em;

    &:hover {
        color: var(--primary-color);
    }
}
