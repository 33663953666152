
// GS Vars
$headerbar-height: 80px;
$headerbar-item-margin: 8px;
$headerbar-sub-height: 48px;
$breadcrumb-height: 35px;


$igStatic: '/igstatic/' !default;




$igBorderRadiusS: 2px;
$igBorderRadiusM: 5px;
$igBorderRadiusL: 10px;
$igBorderRadiusXL: 15px;
