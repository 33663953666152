/**
 * this are the complete public foundation styles including:
 * - css rules
 * - vars
 * - mixins
 */
@import './packages/ingrid/src/lib/foundations/colors/colors.public.scss';
@import './packages/ingrid/src/lib/foundations/typographie/typographie.public.scss';
@import './packages/ingrid/src/lib/foundations/logo/logo.public.scss';
@import './packages/ingrid/src/lib/foundations/forms/forms.public.scss';
@import 'iconfonts/gsiconfont.mixins';

i.tooltip-icon {
  font-size: 1.5em;
  cursor: pointer;
  position: relative;
  bottom: -0.125em;
  margin-top: -0.5em;
  margin-left: -0.2em;
  display: inline-block;

  &:before {
    @include gicon('a120_info_outline');
    color: var(--ig-color-label);
  }
}
