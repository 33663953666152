@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$igStatic}skins/fonts/Titillium_Web/TitilliumWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$igStatic}skins/fonts/Titillium_Web/TitilliumWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$igStatic}skins/fonts/Titillium_Web/TitilliumWeb-SemiBoldItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$igStatic}skins/fonts/Titillium_Web/TitilliumWeb-SemiBold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$igStatic}skins/fonts/Titillium_Web/TitilliumWeb-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$igStatic}skins/fonts/Titillium_Web/TitilliumWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$igStatic}skins/fonts/Titillium_Web/TitilliumWeb-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$igStatic}skins/fonts/Titillium_Web/TitilliumWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
