/* Customizations to the designer theme should be defined here */

@import './extensions/button'; // custom rules for primeNG buttons
@import './extensions/card'; // custom rules for primeNG cards
@import './extensions/color'; // custom rules for primeNG cards
@import './extensions/dialog'; // custom rules for primeNG dialogs
@import './extensions/form'; // custom rules for primeNG form
@import './extensions/grid'; // custom rules for primeNG grid
@import './extensions/menu'; // custom rules for primeNG menu
@import './extensions/overlaypanel'; // custom rules for primeNG overlay panel
@import './extensions/table'; // custom rules for primeNG tables / gs-table
@import './extensions/toast'; // custom rules for primeNG toast
@import './extensions/inputtext'; // custom rules for primeNG toast
@import './extensions/accordion'; // custom rules for primeNG accoredeon
@import './extensions/misc'; // custom rules for primeNG misc

@import './extensions/inputswitch'; // custom rules for primeNG toast
@import './extensions/inputnumber'; // custom rules for primeNG number input
