@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Bold.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-BoldItalic.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-ExtraBold.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-ExtraBold.woff") format("woff");
  font-weight: 900;
}
@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Italic.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Italic.woff") format("woff");
  font-style: italic;
}
@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Light.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-LightItalic.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Regular.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Thin.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-Thin.woff") format("woff");
  font-weight: 100;
}
@font-face {
  font-family:'FSAlbert';
  src:url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-ThinItalic.woff2") format("woff2"),
      url("#{$igStatic}skins/fonts/FSAlbert/Web/FSAlbertWeb-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
