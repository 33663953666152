@import 'foundations_vars';


/// Background of a steps item
/// @group menu
$stepsItemBg: transparent;

/// Border of a steps item
/// @group menu
$stepsItemBorder: none;

/// Text color of a steps item
/// @group menu
$stepsItemTextColor: var(--ig-color-form-clickarea-foreground-bold);

/// Width of a steps itrem number
/// @group menu
$stepsItemNumberWidth: 2.75rem;

/// Height of a steps itrem number
/// @group menu
$stepsItemNumberHeight: 2.75rem;

/// Font size of a steps itrem number
/// @group menu
$stepsItemNumberFontSize: 1.125rem;

/// Color of a steps itrem number
/// @group menu
$stepsItemNumberColor: var(--ig-color-form-clickarea-foreground-bold);

/// Border radius of a steps item number
/// @group menu
$stepsItemNumberBorderRadius: 50%;

/// Font weight of an active steps item number
/// @group menu
$stepsItemActiveFontWeight: 500;

/// Width of a vertical menu such as tieredmenu or context menu
/// @group menu
$menuWidth: 12.5rem;

/// Background of a menu
/// @group menu
$menuBg: var(--ig-color-menu-background);

/// Border of a menu
/// @group menu
$menuBorder: 1px solid var(--ig-color-card-border);

/// Text color of a menu
/// @group menu
$menuTextColor: var(--ig-color-menu-foreground-light);

/// Padding of a menuitem
/// @group menu
$menuitemPadding: 0.25rem 1rem;

/// Border radius of a menuitem
/// @group menu
$menuitemBorderRadius: 0;

/// Text color of a menuitem
/// @group menu
$menuitemTextColor: var(--ig-color-menu-foreground);

/// Icon color of a menuitem
/// @group menu
$menuitemIconColor: var(--ig-color-menu-foreground);

/// Text color of a menuitrem in hover state
/// @group menu
$menuitemTextHoverColor: var(--ig-color-menu-foreground);

/// Icon color of a menuitrem in hover state
/// @group menu
$menuitemIconHoverColor: var(--ig-color-menu-foreground);

/// Background of a menuitrem in hover state
/// @group menu
$menuitemHoverBg: var(--ig-color-menu-background-hover);

/// Text color of a menuitrem in active state
/// @group menu
$menuitemTextActiveColor: var(--ig-color-menu-foreground-highlight);

/// Icon color of a menuitrem in active state
/// @group menu
$menuitemIconActiveColor: var(--ig-color-menu-foreground-highlight);

/// Background of a menuitrem in active state
/// @group menu
$menuitemActiveBg: transparent;

/// Font size of an icon indicating the itrem has a submenu
/// @group menu
$menuitemSubmenuIconFontSize: 0.875rem;

/// Margin of a submenu header
/// @group menu
$submenuHeaderMargin: 0;

/// Padding of a submenu header
/// @group menu
$submenuHeaderPadding: 0.281rem 1rem;

/// Background of a submenu header
/// @group menu
$submenuHeaderBg: var(--ig-color-form-clickarea-background);

/// Text color of a submenu header
/// @group menu
$submenuHeaderTextColor: var(-ig-color-form-clickarea-foreground-light);

/// Border radius of a submenu header
/// @group menu
$submenuHeaderBorderRadius: 0;

/// Font weight of a submenu header
/// @group menu
$submenuHeaderFontWeight: normal;

/// Background of an overlay menu
/// @group menu
$overlayMenuBg: $menuBg;

/// Border of an overlay menu
/// @group menu
$overlayMenuBorder: 0 none;

/// Box shadow of an overlay menu
/// @group menu
$overlayMenuShadow: var(--ig-menu-overlay-box-shadow);

/// Padding of a vertical menu e.g. tieredmenu, contextmenu
/// @group menu
$verticalMenuPadding: 0.5rem 0;

/// Margin of a vertical menuitem
/// @group menu
$verticalMenuitemMargin: 0;

/// Margin of a menuitrem separator
/// @group menu
$menuSeparatorMargin: 0.25rem 0;

/// Padding of a breadcrumb
/// @group menu
$breadcrumbPadding: 1rem;

/// Background of a breadcrumb
/// @group menu
$breadcrumbBg: $menuBg;

/// Border of a breadcrumb
/// @group menu
$breadcrumbBorder: $menuBorder;

/// Text color of a breadcrumb item
/// @group menu
$breadcrumbItemTextColor: $menuitemTextColor;

/// Icon color of a breadcrumb item
/// @group menu
$breadcrumbItemIconColor: $menuitemIconColor;

/// Text color of the breadcrumb item
/// @group menu
$breadcrumbLastItemTextColor: $menuitemTextColor;

/// Icon color of the breadcrumb item
/// @group menu
$breadcrumbLastItemIconColor: $menuitemIconColor;

/// Color of a breadcrumb separator
/// @group menu
$breadcrumbSeparatorColor: $menuitemTextColor;

/// Padding of a horizontal menu e.g. menubar
/// @group menu
$horizontalMenuPadding: 0.5rem;

/// Background of a horizontal menu e.g. menubar
/// @group menu
$horizontalMenuBg: var(--ig-color-white);

/// Border of a horizontal menu e.g. menubar
/// @group menu
$horizontalMenuBorder: $menuBorder;

/// Text color of a horizontal menu e.g. menubar
/// @group menu
$horizontalMenuTextColor: $menuTextColor;

/// Padding of a horizontal menu root item e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemPadding: $menuitemPadding;

/// Border radius of a horizontal menu root item e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemBorderRadius: $borderRadius;

/// Text color of a horizontal menu root item e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemTextColor: $menuitemTextColor;

/// Icon color of a horizontal menu root item e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemIconColor: $menuitemIconColor;

/// Text color of a horizontal menu root item in hover state e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemTextHoverColor: $menuitemTextHoverColor;

/// Icon color of a horizontal menu root item in hover state e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemIconHoverColor: $menuitemIconHoverColor;

/// Background of a horizontal menu root item in hover state e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemHoverBg: $menuitemHoverBg;

/// Text color of a horizontal menu root item in hover active e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemTextActiveColor: $menuitemTextActiveColor;

/// Icon color of a horizontal menu root item in hover active e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemIconActiveColor: $menuitemIconActiveColor;

/// Background of a horizontal menu root item in active state e.g. menubar
/// @group menu
$horizontalMenuRootMenuitemActiveBg: $menuitemActiveBg;
