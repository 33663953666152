@import 'foundations_vars';
.box {
    background-color: var(--ig-color-card-background);
    padding: 10px;

    .box-inner {
        background-color: var(--ig-color-white);
        padding: 20px;
        margin-bottom: 10px;

        &.warning-state {
            -webkit-box-shadow: 0 0px 4px 0 var(--color-orange);
            -motbox-shadow: 0 0px 4px 0 var(--color-orange);
            box-shadow: 0 0px 4px 0 var(--color-orange);
        }

        &.error-state {
            -webkit-box-shadow: 0 0px 4px 0 var(--color-red);
            -motbox-shadow: 0 0px 4px 0 var(--color-red);
            box-shadow: 0 0px 4px 0 var(--color-red);
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &.no-padding {
            padding: 0;
        }

        &.no-bg {
            background: none;
        }
    }
}
